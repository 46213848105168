  import './01_modernizr.min.js'
  import './01_autotrack.js'
  import './08_cssua.min.js'
  import Headroom from './02_headroom.min.js'
  import './03_lazysizes.min.js'
  import './04_magnific-popup.min.js'
  import './09_image-comparison.js'
  import './07_jquery.jscroll.min.js'
  import './10_slick.js'
  import Cookies from 'js-cookie';

  (function($, window, document, undefined) {

    'use strict';

    /**
     * This is a description for FactoryOS object.
     *
     * @namespace FactoryOS
     * @author Mangrove
     */

    var Mangrove = {

      /**
       * Initialize object
       * @function init
       * @memberof FactoryOS
       */

      init: function() {

        this._cache();
        this._events();
        this._initHeadroom();
        this._initPopups();
        this._initInfiniteScroll();
        this._thankYou();
        this._initSliders();
        this._initBlogCards();
        this._initPostCards();
        this._initVideosAjax();
        this._closebanner();

        if($('#form-crm-contact').length) {
          this._initContactForm();
        }

        if($('#form-crm-sign-in').length) {
          this._initSignInForm();
        }

      }, // init()

      /**
       * Cache reusable data
       * @function cache
       * @memberof FactoryOS
       */

      _cache: function() {

        this.$win = $(window);
        this.$html = $('html');
        this.$body = $('body');
        this.$mainWrap = $('main');
        this.$header = $('.js-header');
        this.$mobileNav = $('.js-mobile-nav');
        this.$hamburger = $('.js-hamburger');
        this.$masonryGrid = $('.js-masonry');
        this.$projectFilters = $('.js-project-filters a');
        this.$animateIn = $('.animate-in');
        this.$fadeIn = $('.fade-in');
        this.$gridItem = $('.grid-item');
        this.$postItem = $('.post-item--blog');
        this.$quotes = $('.js-quotes');
        this.$workIntro = $('.js-work-intro');
        this.$homeVideo = document.getElementById('home-video');
        this.$heroControls = $('.hero__controls');
        this.$posts = $('.js-posts');
        this.$pressPosts = $('.js-posts-press');

      }, // _cache()

      /**
       * Attach event listeners
       * @function _events
       * @memberof FactoryOS
       */

      _events: function() {


        this.$html.on(
          'click',
          '.js-hamburger',
          this._showMobileNav.bind(this)
        );

        this.$html.on(
          'click',
          '.js-play-video',
          this._playHeroVideo.bind(this)
        );
        this.$html.on(
          'click',
          '.video--ctrl-mute, .video--ctrl-unmute',
          this._muteHeroVideo.bind(this)
        );

        this.$html.on(
          'click',
          '.video--ctrl-pause',
          this._pauseHeroVideo.bind(this)
        );

        if (this.$homeVideo) {
          this.$homeVideo.addEventListener(
            'ended',
            this._resetHeroVideo
          );
        }

        if (this.$win.width() >= 768) {
          $(this.$homeVideo).hover(function(event) {
            if (event.type === 'mouseenter') {
              $(this).attr('controls', '');
            } else if (event.type === 'mouseleave') {
              $(this).removeAttr('controls');
            }
          });
        } else {
          $(this.$homeVideo).attr('controls', '');
        }

        this.$win.on('load', function() {
          $('body').addClass('is-loaded')
        });

        this.$html.on(
          'click',
          '.js-video-item',
          this._playVideoItem
        );

        this.$html.on(
          'click',
          '.js-thanks-close',
          this._playVideoItem
        );


      }, // _events()

      _initSignInForm() {

        // reload on thank you message
        if($('.form-notices').length) {
          setTimeout(()=>{
            location.href = location
          }, 5000)
        }

        let contactForm = $('#form-crm-sign-in');
        let inputsToHide = [
          'input[name="entity[address1_line3]"]',
          'select[name="entity[fos_tourdevelopertype]"]',
          'select[name="entity[fos_touraecprofessionalconsultant]"]',
          'select[name="entity[leadsourcecode]"]',
          'input[name="entity[fos_websitelead]"]',
          'input[name="entity[fos_tourdate]"]'
        ]

        let developerInputs = [
          'select[name="entity[fos_tourdevelopertype]"]'
        ]

        let acInputs = [
          'select[name="entity[fos_touraecprofessionalconsultant]"]'
        ]

        $('select[name="entity[leadsourcecode]"]').val(8);
        $('input[name="entity[fos_websitelead]"][value="1"]').prop('checked', true);
        let rightNow = new Date().toLocaleString();
        $('input[name="entity[fos_tourdate]"]').val(rightNow);

        inputsToHide.forEach((id) => {
          $(id).closest('.form-group').hide();
        });

        $('select[name="entity[fos_tourcompanytype]"').on('change', (event) => {
          inputsToHide.forEach((id) => {
            $(id).closest('.form-group').hide();
          });

          // developer
          if (event.target.value == '179220000') {
            developerInputs.forEach((id) => {
              $(id).closest('.form-group').show();
            });
          }

          // ac
          if (event.target.value == '179220001') {
            acInputs.forEach((id) => {
              $(id).closest('.form-group').show();
            });
          }

        });

      },

      _initContactForm() {
        // this is set in WYSYWIG as div wrap
        let contactForm = $('#form-crm-contact');
        let inputsToHide = [
          'input[name="entity[companyname]"]',
          'input[name="entity[subject]"]',
          'input[name="entity[fos_projectcity]"]',
          'input[name="entity[fos_projectstate]"]',
          'input[name="entity[fos_estimatedprojectstart]"]',
          'input[name="entity[fos_residentialunitquantity]"]',
          'select[name="entity[fos_financestage]"]',
          'textarea[name="entity[fos_summaryofinterest]"]',
          'select[name="entity[leadsourcecode]"]',
          'input[name="entity[fos_websitelead]"]'
        ]

        let developmentInputs = [
          'input[name="entity[companyname]"]',
          'input[name="entity[subject]"]',
          'input[name="entity[fos_projectcity]"]',
          'input[name="entity[fos_projectstate]"]',
          'input[name="entity[fos_estimatedprojectstart]"]',
          'input[name="entity[fos_residentialunitquantity]"]',
          'select[name="entity[fos_financestage]"]',
          'textarea[name="entity[fos_summaryofinterest]"]',
        ]

        let suppliersInputs = [
          'input[name="entity[companyname]"]',
          'textarea[name="entity[fos_summaryofinterest]"]',
        ]

        let otherInputs = [
          'textarea[name="entity[fos_summaryofinterest]"]',
        ]

        $('select[name="entity[leadsourcecode]"]').val(8);
        $('input[name="entity[fos_websitelead]"][value="1"]').prop('checked', true);


        inputsToHide.forEach((id) => {
          $(id).closest('.form-group').hide();
        });

        $('select[name="entity[fos_reasonforinquiry]"').on('change', (event) => {
          inputsToHide.forEach((id) => {
            $(id).closest('.form-group').hide();
          });

          // development
          if (event.target.value == '179220000') {
            developmentInputs.forEach((id) => {
              $(id).closest('.form-group').show();
            });
          }
          // suppliers
          if (event.target.value == '179220001') {
            suppliersInputs.forEach((id) => {
              $(id).closest('.form-group').show();
            });
          }

          // other
          if (event.target.value == '179220002') {
            otherInputs.forEach((id) => {
              $(id).closest('.form-group').show();
            });
          }
        });

      },


      _initPostCards() {
        const cards = document.querySelectorAll('.js-post-card');
        Array.prototype.forEach.call(cards, card => {
          let down, up, link = card.querySelector('.button');
          if (link) {
            card.onmousedown = () => down = +new Date();
            card.onmouseup = () => {
              up = +new Date();
              if ((up - down) < 200) {
                link.click();
              }
            }
          }
        });
      },

      _playVideoItem: function(e) {
        e.preventDefault();
        var videoItem = $(e.currentTarget);
        var videoType = videoItem.data('type');
        var videoSource = videoItem.data('source');
        var videoDate = videoItem.data('date');
        var videoTitle = videoItem.data('title');
        var videoSrc = videoItem.data('video');

        if (videoType == 'external' && videoSource == 'youtube') {

          $.magnificPopup.open({
            items: {
              src: videoSrc,
              type: 'iframe',
            },
            midClick: true,
            removalDelay: 100,
            mainClass: 'popup--animate',
            iframe: {
              markup: '<div class="mfp-video-header">' +
                '<div class="mfp-close"></div>' +
                '</div>' +
                '<div class="mfp-iframe-scaler">' +
                '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' +
                '</div>', // HTML markup of popup, `mfp-close` will be replaced by the close button
              patterns: {
                youtube: {
                  index: 'youtube.com',
                  id: 'v=',
                  src: '//www.youtube.com/embed/%id%?rel=0&autoplay=1'
                }
              }
            }
          });
        } else if (videoType == 'external' && videoSource == 'reuters') {
          $.magnificPopup.open({
            items: {
              src: videoSrc,
              type: 'iframe',
            },
            midClick: true,
            removalDelay: 100,
            mainClass: 'popup--animate',
            iframe: {
              markup: '<div class="mfp-video-header">' +
                '<div class="mfp-close"></div>' +
                '</div>' +
                '<div class="mfp-iframe-scaler">' +
                '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' +
                '</div>', // HTML markup of popup, `mfp-close` will be replaced by the close button
              patterns: {
                reuters: {
                  index: 'reuters.com',
                  id: 'videoId=',
                  src: '//www.reuters.com/assets/iframe/yovideo?videoId=%id%'
                }
              }
            }
          });
        } else if (videoType == 'internal') {

          $.magnificPopup.open({
            items: {
              src: '#video-popup',
              type: 'inline',
            },
            midClick: true,
            removalDelay: 100,
            mainClass: 'popup--animate',
            callbacks: {
              open: function() {
                $('html').css('margin-right', 0);
                $(this.content).find('h5').text(videoDate);
                $(this.content).find('h3').text(videoTitle);
                $(this.content).find('source').attr('src', videoSrc);
                $(this.content).find('video')[0].on('load');
                $(this.content).find('video')[0].play();
              },
              close: function() {
                $(this.content).find('video')[0].on('load');
              }
            }
          });
        } else {
          return false;
        }

      },

      _initSliders: function() {
        $('.js-slider-pc').slick({
          arrows: false,
          dots: false,
          autoplay: true,
          autoplaySpeed: 3000,
          adaptiveHeight: true
        })

        $('.js-slider-home').slick({
          slidesToShow: 4,
          slidesToScroll: 4,
          dots: true,
          responsive: [{
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
                arrows: true
              }
            },
            {
              breakpoint: 960,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                dots: true,
                arrows: true,
              }
            },
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                dots: true,
                arrows: true,
              }
            },
          ]

        });
      },

      _thankYou: function() {
        if ($('.js-thanks').length) {
          setTimeout(function() {
            $('.js-thanks').removeClass('has-thanks');
          }, 15000);
        }

        $('.js-thanks-close').on('click', function(){
          $('.js-thanks').removeClass('has-thanks');
        })
      },

      _initBlogCards() {
        const cards = document.querySelectorAll('.js-slider-card');
        Array.prototype.forEach.call(cards, card => {
          let down, up, link = card.querySelector('.button--link');
          card.onmousedown = () => down = +new Date();
          card.onmouseup = () => {
            up = +new Date();
            if ((up - down) < 200) {
              link.click();
            }
          }
        });
      },

      _initInfiniteScroll: function() {

        this.$posts.jscroll({
          loadingHtml: '<img class="blog-item__loading" src="/wp-content/themes/_factory-os-2017/library/img/icon-loading.svg">',
          padding: 0,
          autoTrigger: false,
          nextSelector: '.js-pagination a',
          contentSelector: '.js-posts',
          debug: true
        });

        this.$pressPosts.jscroll({
          loadingHtml: '<img class="blog-item__loading" src="/wp-content/themes/_factory-os-2017/library/img/icon-loading.svg">',
          padding: 0,
          autoTrigger: false,
          nextSelector: '.js-pagination-press a.next',
          contentSelector: '.js-posts-press',
          debug: true
        });
      },

      _playHeroVideo: function(e) {
        e.preventDefault();

        //  ga('send', 'event', 'video', 'click', 'Home Video');

        var heroVideo = $('.js-home-video')

        if (this.$win.width() <= 768) {

          if (this.$homeVideo.requestFullscreen) {
            this.$homeVideo.requestFullscreen();
          } else if (this.$homeVideo.msRequestFullscreen) {
            this.$homeVideo.msRequestFullscreen();
          } else if (this.$homeVideo.mozRequestFullScreen) {
            this.$homeVideo.mozRequestFullScreen();
          } else if (this.$homeVideo.webkitRequestFullscreen) {
            this.$homeVideo.webkitRequestFullscreen();
          } else {
            this.$homeVideo.play();
          }
        } else {
          this.$homeVideo.play();

          heroVideo.toggleClass('is-playing');
        }

      },

      _muteHeroVideo: function(e) {
        if (this.$homeVideo.muted) { // unmute + show mute button
          this.$homeVideo.muted = false
        } else {
          this.$homeVideo.muted = true
        }
      },

      _pauseHeroVideo: function(e) {
        if (this.$homeVideo.paused) { // play video + show pause button
          this.$homeVideo.play()
        } else {
          this.$homeVideo.pause()
        }
      },

      _resetHeroVideo: function(e) {
        var heroVideo = $('.hero--video')
        heroVideo.removeClass('is--large-video')
        heroVideo.addClass('is--small-video')
        this.$homeVideo.pause()
        this.$homeVideo.currentTime(0)
      },

      _initPopups: function() {
        $('.js-popup').magnificPopup({
          type: 'inline',
          midClick: true,
          removalDelay: 100,
          mainClass: 'popup--animate'
        });

        $('.js-popup-video').magnificPopup({
          type: 'iframe',
          midClick: true,
          removalDelay: 100,
          mainClass: 'popup--animate'
        });

        $('.js-popup-video-home').magnificPopup({

          type: 'iframe',
          midClick: true,
          removalDelay: 100,
          items: {
              src: '#video-sec' 
          },
          iframe: {
            markup: '<div class="modal__bg modal-cont"><div class="wrapper wrapper--internal vid-home mod_wrap">'+
                      '<div class="mfp-close"></div>'+
                      '<div class="content__text video_container">'+
                        '<div class="mfp-iframe-scaler">' +
                          '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' +
                        '</div>'+
                      '</div>'+
                    '</div></div>'
          },
          mainClass: 'popup--animate'
        });

        $('.js-popup-video').magnificPopup({
          type: 'iframe',
          midClick: true,
          removalDelay: 100,
          mainClass: 'popup--animate'
        });
        
        $(document).ready(function () {
          setTimeout(function() {
            if ($("body").hasClass("home")) {
             if ($('#popup-video, #popup-home').length && Cookies.get('mod_wrap') !== 'close') {
               $.magnificPopup.open({
                  items: {
                      src: '#popup-video, #popup-home' 
                  },
                  type: 'inline',
                  mainClass: 'popup--animate',
                  callbacks: {
                    open: function() {
                      Cookies.set('mod_wrap', 'close', { expires: 1/24 })
                    }
                  }
                });
              }
            }  
          }, 1000);
          
          if (!$("body").hasClass("home") && ($('#popup-global').length)) {
              $.magnificPopup.open({
                items: {
                  src: '#popup-global' 
                },
                type: 'inline',
                mainClass: 'popup--animate'
              });
            }
        });
      },

      _initHeadroom: function() {
        var theHeader = document.querySelector('.js-header');
        var headroom = new Headroom(theHeader);
        headroom.init();
      },


      _showMobileNav: function(e) {
        e.preventDefault();
        this.$mobileNav.toggleClass('is-active');
        this.$hamburger.toggleClass('is-active');
        this.$body.toggleClass('has-nav');
      },

      _initVideosAjax: function() {
        var videosJSAjax = function () {
          var category = $('#video-category').val();
          // console.log(category);
          var keyword = jQuery('#videos-keyword').val();
          // console.log(keyword);
          var order = jQuery('#video-sort').val();
          // console.log(order);

          $.ajax({
            url:my_ajax_object.ajax_url,
            type:'POST',
            data:{
              action:'fos_videos_ajax',
              category:category,
              keyword:keyword,
              order:order
            },

            beforeSend:function(xhr){
              $('#ajax-loading .blog-item__loading').show();
            },
            success:function(response){
              $('#ajax-loading .blog-item__loading').hide();
              // console.log($(response.data));
              $('#videos-list').html($(response.data));
            },
            error: function(){
              console.log('Error');
            }
          });

        }
        $('#videos-keyword').keypress(function (event) {
          if (event.keyCode === 10 || event.keyCode === 13) {
              event.preventDefault();
          }
        });

        $('#video-category').change(videosJSAjax);
        $('#video-sort').change(videosJSAjax);
        $('#videos-keyword').keyup(videosJSAjax);
      },

      _closebanner: function() {
        $(document).ready(function () {
          $('#hero__banner').on('click', function(){
              this.style.display = 'none';
              $("#hero--home").css('margin-top','0px');
              $(".headroom--not-top").css('height','inherit');
              $(".nav__list").css('margin-top','0px');
          });
        });
      }

    }; // FactoryOS

    Mangrove.init();

  })(jQuery, window, document);
